import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <footer>
      <p>NotYet</p>
    </footer>
  );
};

export default Footer;
